<template>
	<div v-show="getType" class="earnings" :style="{ minHeight: minHeight + 'px' }">
		<van-nav-bar
			title="收益報表"
			left-arrow
			class="qjc-agent-navbar"
			@click-left="$router.back()"
		></van-nav-bar>
		
		<!-- 通知 -->
		<van-notice-bar
		  :left-icon="require('@/assets/images/agent_earnings_tongzhi.png')"
		  :scrollable="false"
		  color="#6883FB"
		  background="transparent"
		  class="notice qjc-fts-26"
		  @click="$router.push('/agent/earnings/rules')"
		  text="[官方通知]《收益報表計算規則》"
		/>
		
		<div class="card qjc-c-fff qjc-fts-26">
			<dl class="prev-month">
				<dt>上月收益</dt>
				<dd><span>{{ lastMonthProfit }}</span>港元</dd>
			</dl>
			
			<div @click="$router.push('/agent/earnings/withdraw')" class="account qjc-fts-30">提現賬戶</div>
			
		</div>
		
		<div :style="{ minHeight: boxMinHeight + 'px' }" class="detail qjc-bg-fff" ref="box">
			<div @click="$router.push('/agent/earnings/detail')" class="title qjc-fts-34">
				<span>收益明細</span>
				<img class="title-arrow" src="../../../../../xgtj/assets/img/arrow_down.png" alt="箭头">
			</div>
			
			<van-tabs v-model="nowMonth" @click="checkMonth" background="#E1E4F0" :ellipsis="false" title-active-color="#6883FB" title-inactive-color="#8C9699">
			  <van-tab v-for="month in allMonth" :key="month.value" :title="month.text" :name="month.value"></van-tab>
			</van-tabs>
			
			<dl class="total qjc-texta-c">
				<dt>總收益(港元)</dt>
				<dd>{{ totalProfit }}</dd>
			</dl>
			  
			<!-- 代理人 -->
			<div v-if="type == '1'" class="list qjc-clearfix qjc-texta-l">
			  <dl>
				  <dt>套餐開通次數(次)</dt>
				  <dd>{{ sellTimes }}</dd>
			  </dl>
			  <dl>
				  <dt>直銷收益(港元)</dt>
				  <dd>{{ sellProfit }}</dd>
			  </dl>
			  <dl v-for="(item, index) in profit" :key="index">
				  <dt>{{ hanOfIndex[index] }}級代理人收益(港元)</dt>
				  <dd>{{ item.profit }}</dd>
			  </dl>
			</div>
			
			<!-- kol -->
			<div v-if="type == '2'" class="list qjc-clearfix qjc-texta-l">
			  <dl v-for="(item, index) in profit" :key="index">
				  <dt>{{ hanOfIndex[index] }}級代理人收益(港元)</dt>
				  <dd>{{ item.profit }}</dd>
			  </dl>
			</div>
			  
			<!-- 机构 -->
			<div v-if="type == '3'" class="list qjc-clearfix qjc-texta-l">
			  <dl>
				  <dt>套餐開通次數(次)</dt>
				  <dd>{{ sellTimes }}</dd>
			  </dl>
			  <dl>
				  <dt>直銷收益(港元)</dt>
				  <dd>{{ sellProfit }}</dd>
			  </dl>
			  <dl>
				  <dt>員工收益(港元)</dt>
				  <dd>{{ staffProfit }}</dd>
			  </dl>
			</div>
			 
			<!-- 员工 -->
			<div v-if="type == '4'" class="list qjc-clearfix qjc-texta-l">
			   <dl>
			 	  <dt>套餐開通次數(次)</dt>
			 	  <dd>{{ sellTimes }}</dd>
			   </dl>
			   <dl>
			 	  <dt>直銷收益(港元)</dt>
			 	  <dd>{{ sellProfit }}</dd>
			   </dl>
			</div>
			  
		</div>
		
		<div v-if="type != 4" class="agent qjc-bg-fff">
			<div class="title qjc-fts-34">
				<span>代理人人数</span>
			</div>
			
			<!-- 代理人、KOL -->
			<div v-if="type == '1' || type == '2'" class="list qjc-clearfix qjc-texta-l">
			  <dl v-for="(item, index) in profit" :key="index">
				  <dt>{{ hanOfIndex[index] }}級代理人人數(人)</dt>
				  <dd>{{ item.agent }}</dd>
			  </dl>
			</div>
			
			<!-- 机构 -->
			<div v-if="type == '3'" class="list qjc-clearfix qjc-texta-l">
			  <dl>
				  <dt>員工人數(人)</dt>
				  <dd>{{ staffNum }}</dd>
			  </dl>
			</div>
			
			
		</div>
		
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, NoticeBar, Tab, Tabs, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(NoticeBar)
	   .use(Tab)
	   .use(Tabs)
	   .use(Toast);
	   
	import { profit, agentInfo } from '@/js/axios'
	
	export default{
		name: 'agentEarnings',
		data() {
			return {
				minHeight: window.innerHeight,// 最低高度为全屏高度
				
				getType: false,// 是否获取用户类型
				
				nowMonth: '',// 当前年月
				allMonth: [],
				
				lastMonthProfit: 0,// 上月收益
				
				totalProfit: 0,// 总收益
				
				// 用户类型
				type: '',
				
				boxMinHeight: 0,// 收益最小高度
				
				// 通用
				sellTimes: 0,// 套餐开通次数
				sellProfit: 0,// 直销收益
				
				staffProfit: 0,// 员工收益
				staffNum: 0,// 员工人数
				
				// 各级代理人收益及代理人数
				profit: [],
				
				hanOfIndex: ['一', '二', '三', '四', '五', '六', '七', '八', '九']
				
			}
		},
		mounted() {
			agentInfo().then(res => {
				if(res.code == 200){
					// 存储用户信息
					var userInfo = res.data; 
			
					this.type = userInfo.type;// 1.代理人 2.KOL 3.企业 4.员工
					this.getType = true;
			
				}else{
					Toast.fail(res.msg);
				}
			});
			
			// 获取月份
			this.allMonth = this.months();
			
			// 默认显示当月
			this.checkMonth(this.nowMonth);
			
			this.$nextTick(() => {
				if(this.type == 4){// 不显示代理人数(员工)则占满一屏
					// 内容最小一屏
					this.boxMinHeight = this.minHeight - this.$refs.box.offsetTop - (document.querySelector('html').style.fontSize.replace('px','')*0.24);
					
				}
			})
			
		},
		methods: {
			// 最近一年
			months() {
				var months = [],
					y = new Date().getFullYear(),
					m = new Date().getMonth()+1;
					
				// 选中当前年月
				this.nowMonth = y + '-' + (m<10?'0'+m:m);
				
				for(var i=0;i<12;i++){
					let mc = m - i;
					
					if(mc == 0){
						y--;
					}
					if(mc <= 0){
						mc += 12;
					}
					
					months.push({
						text: (mc==m?'當':mc) + '月',
						value: y + '-' + (mc<10?'0'+mc:mc)
					});
				}
				
				return months;
			},
			
			checkMonth(name, title) {// name为当月参数信息(YYYY-MM,如:2021-01)
				profit({
					month: name
				}).then(res => {
					// console.log(res)
					if(res.code == 200){
						var data = res.data;
						
						// 从注册以后月份开始显示(已满一年不操作，默认显示为12个月)
						var now = new Date(),
							nowY = now.getFullYear(),
							nowM = now.getMonth()+1,
							rDate = new Date(data.created_at*1000),
							rDateY = rDate.getFullYear(),
							rDateM = rDate.getMonth()+1,
							during = (nowY - rDateY)*12 + (nowM - rDateM + 1);// 当前时间与注册时间间隔月份
							
						if(during < 12){// 未满一年
							this.allMonth = this.allMonth.slice(0, during);
						}
						
						// 通用
						this.lastMonthProfit = data.lastMonthProfit;
						this.totalProfit = data.totalProfit;
						this.sellTimes = data.sellTimes;
						this.sellProfit = data.sellProfit;
						
						this.profit = data.profit;
						
						// 机构
						if(this.type == 3){
							this.staffProfit = data.staffProfit;
							this.staffNum = data.staffNum;
						}
						
					}else{
						Toast.fail(res.msg);
					}
				});
			}
		}
	}
</script>

<style scoped>
	.notice >>> .van-notice-bar__wrap{
		margin-left: 0.12rem;
	}
	.detail >>> .van-tabs__wrap{
		height: 0.7rem;
	}
	.detail >>> .van-tab{
		flex: none;
		font-size: 0.3rem;
		font-weight: 400;
		line-height: 0.7rem;
		padding: 0 0.38rem;
	}
	.detail >>> .van-tabs__line{
		display: none;
	}
</style>
<style lang="scss" scoped>
	.earnings{
		color: #3B4549;
		background-color: #EBEDF5;
		padding-bottom: 0.28rem;
		
		.notice{
			align-items: center;
			height: 0.6rem;
			padding: 0 0.24rem;
		}
		
		.card{
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			padding: 1.11rem 0.48rem;
			border-radius: 0 0 0.12rem 0.12rem;
			background: url(../../../assets/images/agent_earnings_cardbg.png) no-repeat;
			background-size: 100% 100%;
			
			.prev-month{
				text-align: left;
				
				dt{
					line-height: 0.36rem;
					margin-bottom: 0.04rem;
				}
				span{
					font-size: 0.64rem;
					line-height: 0.64rem;
				}
			}
			
			.account{
				width: 1.68rem;
				height: 0.56rem;
				line-height: 0.56rem;
				border-radius: 0.28rem;
				border: 0.01rem solid #fff;
				margin-bottom: 0.08rem;
				
				&:active{
					opacity: .8;
				}
			}
			
		}
		
		.detail,
		.agent{
			border-radius: 0.12rem;
			margin: 0.24rem 0.24rem 0;
			
			.title{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 0.94rem;
				background: url(../../../assets/images/agent_earnings_ticon.png) no-repeat;
				background-size: 0.31rem 0.33rem;
				background-position: 0.24rem center;
				padding: 0 0.24rem 0 0.68rem;
				
				.title-arrow{
					width: 0.26rem;
					height: 0.15rem;
					transform: rotateZ(-90deg);
				}
			}
			
			dl{
				padding-left: 0.38rem;
				
				dt{
					color: #8C9699;
					font-size: 0.26rem;
					line-height: 0.26rem;
				}
				dd{
					font-size: 0.4rem;
					line-height: 0.4rem;
					margin-top: 0.28rem;
				}
				
				&.total{
					border-bottom: 0.01rem solid #E1E3EB;
					padding: 0.37rem 0 0.31rem;
					
					dd{
						font-size: 0.48rem;
						line-height: 0.48rem;
					}
				}
			}
			
			.list{
				padding-top: 0.47rem;
				
				dl{
					width: 50%;
					height: 1.58rem;
					float: left;
				}
			}
			
		}
				
		.agent{
			.title{
				border-bottom: 0.01rem solid #E1E3EB;
				background-image: url(../../../assets/images/agent_earnings_people.png);
				background-size: 0.37rem 0.32rem;
			}
		}
		
	}
</style>
